* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* overflow-x: hidden; */
}
body {
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  display: none;
}

.slick-arrow {
  display: none !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
